<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div style="flex:1">
                <LabelledInputText
                    label="Version"
                    placeholder="Version du build"
                    :required="true"
                    :readonly="isReadOnly"
                    v-model="localApplicationBuild.version"
                    @validated="isVersionValid = $event"
                    @input="hasChanged = true"
                />
            </div>
            <div style="flex:1">
                <LabelledInput label="Version template" :required="true">
                    <b-form-select
                        v-model="selectedTemplateConfig"
                        :options="templateConfigOptions"
                        :disabled="isReadOnly"
                        @input="hasChanged = true"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled
                                >-- Version du template --</b-form-select-option
                            >
                        </template></b-form-select
                    >
                </LabelledInput>
            </div>
        </div>

        <div class="text-right">
            <small class="font-italic" v-show="isEditMode">
                <b-icon-info-circle />
                Création le
                {{ localApplicationBuild.createdAt | dateFromISO }} ~ Dernière
                modification le
                {{ localApplicationBuild.updatedAt | dateFromISO }}
            </small>
        </div>

        <div>
            <b class="text-kalkin-2">
                Notes de version
            </b>
        </div>

        <b-textarea
            placeholder="Pas de notes de version"
            v-model="localApplicationBuild.notes"
            :plaintext="isReadOnly"
            @input="hasChanged = true"
        />

        <div class="k-flex-row-2 mt-2">
            <b class="text-kalkin-2">
                Configuration
            </b>
            <div>
                <b-form-file
                    v-model="configFile"
                    accept="application/json"
                    ref="inputConfigFile"
                    style="display:none"
                />
                <b-button
                    v-show="!isReadOnly"
                    size="xs"
                    variant="outline-kalkin-1"
                    @click="selectFile"
                >
                    <b-icon-download /> Charger depuis un fichier
                </b-button>
            </div>
        </div>

        <b-textarea
            class="text-monospace"
            style="font-size:10px"
            :rows="6"
            placeholder="Pas de configuration"
            v-model="stringifiedConfig"
            :plaintext="isReadOnly"
            :state="isConfigValid && null"
            @input="hasChanged = true"
        />

        <div class="k-flex-center mt-3">
            <b-button
                variant="kalkin-2"
                @click="save"
                v-show="!isReadOnly"
                :disabled="!isValid || !hasChanged"
            >
                Enregistrer
            </b-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import notifier from "@/tools/notifier";
import LabelledInputText from "@/components/ui/item/LabelledInputText";
import LabelledInput from "@/components/ui/item/LabelledInput";

const defaultApplicationBuild = {
    version: undefined,
    notes: undefined,
    config: undefined,
};

export default {
    components: {
        LabelledInputText,
        LabelledInput,
    },

    props: {
        applicationid: { type: [String, Number], required: true },
        applicationbuild: { type: Object, required: false },
        isReadOnly: { type: [Boolean, String], default: true },
    },

    data: function() {
        return {
            hasChanged: false,
            isEditMode: Boolean(this.applicationbuild),
            isVersionValid: this.applicationbuild
                ? Boolean(this.applicationbuild.version)
                : false,
            isConfigValid: true,

            templateConfigOptions: [],
            selectedTemplateConfig: this.applicationbuild
                ? this.applicationbuild.TemplateConfigId
                : null,

            localApplicationBuild: Vue.util.extend(
                {},
                this.applicationbuild || defaultApplicationBuild,
            ),

            configFile: null,
        };
    },
    computed: {
        isValid() {
            return (
                this.isVersionValid &&
                this.isConfigValid &&
                this.selectedTemplateConfig
            );
        },

        stringifiedConfig: {
            get() {
                return JSON.stringify(
                    this.localApplicationBuild.config || undefined,
                    null,
                    4,
                );
            },
            set(value) {
                try {
                    this.localApplicationBuild.config = JSON.parse(
                        value || null,
                    );
                    this.isConfigValid = true;
                } catch (error) {
                    this.isConfigValid = false;
                }
            },
        },
    },

    watch: {
        async configFile(file) {
            if (file) {
                const data = await new Response(file).text();
                try {
                    let text = JSON.parse(data);
                    this.localApplicationBuild.config = text;
                    this.hasChanged = true;
                } catch (error) {
                    notifier.error(
                        "Le fichier n'est pas dans un format JSON valide",
                    );
                }
            }
            this.configFile = null;
        },
    },

    methods: {
        async save() {
            if (this.isEditMode) {
                await this.$store.dispatch(
                    "application/updateApplicationBuild",
                    {
                        ...this.localApplicationBuild,
                        applicationBuildId: this.localApplicationBuild.id,
                        templateConfigId: this.selectedTemplateConfig,
                    },
                );
            } else {
                await this.$store.dispatch(
                    "application/createApplicationBuild",
                    {
                        ...this.localApplicationBuild,
                        applicationId: this.applicationid,
                        templateConfigId: this.selectedTemplateConfig,
                    },
                );
            }
            this.$emit("saved");
            this.hasChanged = false;
        },

        selectFile() {
            this.$refs.inputConfigFile.$el.childNodes[0].click();
        },
    },

    async beforeMount() {
        const templateConfigs = await this.$store.dispatch(
            "application/getTemplateConfigs",
            { applicationId: this.applicationid },
        );

        this.templateConfigOptions = templateConfigs.map(c => {
            return { text: c.version, value: c.id };
        });
    },
};
</script>

<style lang="scss" scoped>
textarea:read-only {
    padding: 6px 13px;
    border-radius: 6px;
    background: #f1f1f1;
}
</style>
