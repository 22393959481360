<template>
    <b-input-group>
        <b-input-group-prepend>
            <b-input-group-text :class="{ invalid: inputState === false }"
                >{{ label }}&nbsp;
                <strong class="text-danger" v-show="required"
                    >*</strong
                ></b-input-group-text
            >
        </b-input-group-prepend>
        <b-form-input
            :class="{ 'invalid-bg': inputState === false }"
            :value="value"
            :placeholder="placeholder"
            :state="inputState"
            @input="onInput"
            :readonly="readonly"
        ></b-form-input>
    </b-input-group>
</template>

<script>
export default {
    props: {
        value: { type: String, default: "" },
        label: String,
        placeholder: String,
        required: Boolean,
        readonly: Boolean,
        forceValidation: { type: Boolean, default: false },
    },
    data: function() {
        return {
            hasChanged: false,
        };
    },
    computed: {
        inputState() {
            if (this.required && (this.hasChanged || this.forceValidation)) {
                return this.value && this.value.length > 0 ? null : false;
            } else {
                return null;
            }
        },
    },
    methods: {
        onInput(value) {
            this.hasChanged = true;
            this.$emit("input", value);
            this.$emit("validated", this.required && value.length > 0);
        },
    },
};
</script>

<style scoped>
.invalid {
    border-color: red;
}
.invalid-bg {
    background-color: rgba(255, 0, 0, 0.15);
}
</style>
