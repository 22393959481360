<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une configuration"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Résultats par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            show-empty
            empty-text="Aucune utilisation"
            empty-filtered-text="Aucune utilisation trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="goProject"
            striped
        >
            <template #cell(project)="data">
                {{ data.item.Project.name }}
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        clickTarget: { type: String, default: "AdminProject" },
        buildId: [String, Number],
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            fields: [
                {
                    key: "name",
                    label: "Configuration",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold",
                },
                {
                    key: "project",
                    label: "Nom du projet",
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "application/countFSPApplicationBuildConfigs",
                {
                    buildId: this.buildId,
                    filter: `name substring ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            return await this.$store.dispatch(
                "application/getFSPApplicationBuildConfigs",
                {
                    buildId: this.buildId,
                    filter: `name substring ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
        },

        goProject(item) {
            this.$router.push({
                name: this.clickTarget,
                params: { projectId: item.Project.id },
            });
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
