<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Détails du build
            </h1>
            <div class="k-flex-row">
                <small class="text-kalkin-2">Mode edition</small>
                <b-form-checkbox class="k-switch" v-model="isEditMode" switch />
            </div>

            <b-button size="sm" variant="outline-danger" v-b-modal.ModalDanger>
                <b-icon-trash /> Supprimer le build
            </b-button>
        </div>

        <FormApplicationBuild
            class="mx-3"
            :key="applicationBuild.id"
            :applicationid="applicationId"
            :applicationbuild="applicationBuild"
            :is-read-only="!isEditMode"
        />

        <h3 class="k-flex-row">
            Utilisations du build
            <div class="k-flex-line" />
        </h3>

        <TableApplicationBuildUses
            class="mx-3"
            :build-id="applicationBuildId"
        />

        <!-- Modals -->
        <ModalDanger
            message="Voulez-vous vraiment supprimer ce build?"
            @confirmed="onDeleteConfirmed"
        />
    </div>
</template>

<script>
import FormApplicationBuild from "@/components/model/applicationbuild/FormApplicationBuild";
import TableApplicationBuildUses from "@/components/model/applicationbuild/TableApplicationBuildUses";
import ModalDanger from "@/components/modal/ModalDanger";
export default {
    components: {
        FormApplicationBuild,
        TableApplicationBuildUses,
        ModalDanger,
    },

    data: function() {
        return {
            applicationId: this.$route.params.applicationId,
            applicationBuildId: this.$route.params.buildId,
            applicationBuild: {},
            isEditMode: false,
        };
    },

    methods: {
        async onDeleteConfirmed() {
            await this.$store.dispatch("application/deleteApplicationBuild", {
                applicationBuildId: this.applicationBuildId,
            });
            this.$router.push({
                name: "AdminApplication",
                applicationId: this.applicationId,
            });
        },
    },

    async beforeMount() {
        this.applicationBuild = await this.$store.dispatch(
            "application/getApplicationBuild",
            {
                applicationBuildId: this.applicationBuildId,
            },
        );
    },
};
</script>

<style lang="scss" scoped></style>
