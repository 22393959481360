<template>
    <b-input-group>
        <b-input-group-prepend>
            <b-input-group-text
                >{{ label }}&nbsp;
                <strong class="text-danger" v-show="required"
                    >*</strong
                ></b-input-group-text
            >
        </b-input-group-prepend>
        <slot />
    </b-input-group>
</template>

<script>
export default {
    props: {
        label: String,
        required: Boolean,
    },
};
</script>

<style scoped></style>
